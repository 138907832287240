import React from 'react';
import { graphql } from 'gatsby';
import { PageTemplate } from '../gatsby-theme-wordpress/templates/page';
import ProjectsMenu from '../components/projects-menu';

export const pageQuery = graphql`
  query Project($id: String) {
    page: wordpressWpProjects(id: { eq: $id }) {
      yoast_meta {
        name
        content
        property
      }
      yoast_title
      yoast_json_ld {
        wordpress__context
        wordpress__graph {
          wordpress__type
          wordpress__id
          name
          description
          width
          height
          inLanguage
          datePublished
          dateModified
          isPartOf {
            wordpress__id
          }
        }
      }
      
      content
    }
  }
`;

const ProjectsTemplate = (props) => (
  <PageTemplate
    {...props}
    AsideComponent={ProjectsMenu}
  />
);

export default ProjectsTemplate;
