import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import useProjects from './use-projects';
import useProjectsLanding from './use-projects-landing';

const activeClassName = 'sfadghnhsdgfgxafeg';

const ProjectsMenu = () => {
  const projects = useProjects();
  const landingPage = useProjectsLanding();
  return (
    <div>
      {landingPage?.node && (
        <Button to={landingPage.node.link}>
            {landingPage.node.title}
        </Button>
        )}
      <Ul>
        {projects.map(({ node }) => (
          <li key={node.id}>
            <StyledLink activeClassName={activeClassName} to={node.link}>
              {node.title}
            </StyledLink>
          </li>
        ))}
      </Ul>
    </div>
  );
};

const Button = styled(Link)`
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  padding: .5rem 2rem;
  border: ${(props) => props.theme.borderSize} solid ${(props) => props.theme.primaryColor};
  border-radius: ${(props) => props.theme.borderRadius};
  color: ${(props) => props.theme.primaryColor};
  text-decoration: none;
  text-align: center;
  width: 100%;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    width: auto;
  }
  &:after {
    position: absolute;
    top: .9rem;
    left: 1rem;
    border-style: solid;
    border-width: 0.08rem 0.08rem 0 0;
    content: '';
    display: inline-block;
    height: 0.45rem;
    transform: rotate(-45deg);
    vertical-align: top;
    width: 0.45rem;
    transform: rotate(225deg);
    transition: all 0.1s ease-in-out;
  }
  &:hover {
    &:after {
      left: .5rem;
    }
  }
`;

const StyledLink = styled(Link)`
  display: block;
  font-size: 0.8889rem;
  text-decoration: none;
  color: ${(props) => props.theme.fontColor};
  padding: .5rem 0;
  &.${activeClassName},
  &:hover {
    color: ${(props) => props.theme.primaryColor};
    font-weight: 500;
  }
`;

const Ul = styled.ul`
  margin: 2rem 0 0 0;
  list-style: none;
  border-left: .2rem solid ${(props) => props.theme.primaryColor};
  padding: 0 0 0 1rem;

`;

export default ProjectsMenu;
