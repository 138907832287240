import { useStaticQuery, graphql } from 'gatsby';
import { useLanguage } from 'gatsby-theme-wordpress/src/context/language';

export default () => {
  const language = useLanguage();
  const { landingPage } = useStaticQuery(graphql`
    query useProjectsLanding {
      landingPage: allWordpressPage(filter: {
        template: { eq: "templates/template-projects.php" }
        status: { eq: "publish" }
      }) {
        edges {
          node {
            wpml_current_locale
            title
            link
          }
        }
      }
    }
  `);

  return landingPage?.edges?.find(({ node }) => node.wpml_current_locale === language);
};
